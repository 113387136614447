import {
  differenceInDays,
  isToday,
  formatRelative,
  format,
} from 'date-fns';

import ptBr from "date-fns/locale/pt-BR";

export const formatDateTime = (date) => {
  if (!date) return;

  const dateObj = new Date(date);

  if (dateObj) {
    return formatRelative(dateObj, new Date(), {
      locale: ptBr
    });
  }

  return date;
}

export const formatDateTimeComplete = (date) => {
  if (!date) return;

  const dateObj = new Date(date);

  if (dateObj) {
    return format(dateObj, 'dd/MM/yyyy HH:mm', {
      locale: ptBr
    });
  }

  return date;
}

export const formatDateTimeDay = (date) => {
  if (!date) return;

  const dateObj = new Date(date);

  if (dateObj) {
    return format(dateObj, 'dd/MM/yyyy', {
      locale: ptBr
    });
  }

  return date;
}

export const formatWithLocale = (date, formatString) => {
  return format(date, formatString, {
    locale: ptBr
  });
};

export const formatObjectToMonthAndYear = (date) => {
  return format(date, 'MMMM/yyyy', {
    locale: ptBr
  });
}

export const daysTranslate = (date) => {
  const dateObj = new Date(date);
  const days = differenceInDays(new Date(), dateObj)

  if (isToday(dateObj)) {
    return 'Hoje';
  }

  return `${days}d`;
};

export const isYearPeriod = (period) => {
  return ['year', 'year_before'].includes(period);
};

export const isMonthPeriod = (period) => {
  return ['month', 'month_before'].includes(period);
};

export const isQuarterPeriod = (period) => {
  return ['quarter', 'quarter_before'].includes(period);
};

export const isWeekPeriod = (period) => {
  return ['week', 'week_before'].includes(period);
};