import React from 'react';
import { AppBar, IconButton, makeStyles, Toolbar, Typography, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { GridCloseIcon } from '@material-ui/data-grid';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  toolbarButtons: {
    marginLeft: 'auto',
  },
});

const DialogTitle = withStyles(styles)(({
  title,
  // icon,
  children,
  onClose,
  fullscreen,
  setFullscreen
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleFullScreen = () => {
    setFullscreen(!fullscreen);
  };

  return (
    <AppBar position="static">
      <Toolbar style={{ paddingRight: 8 }}>
        <Typography variant="h5" noWrap>
          {title}
        </Typography>

        <div className={classes.toolbarButtons}>
          {children}

          {!matches && setFullscreen && (
            <IconButton aria-label="fullscreen" onClick={toggleFullScreen} style={{ marginLeft: 0 }}>
              {!fullscreen && <FullscreenIcon style={{ color: 'white' }} />}
              {fullscreen && <FullscreenExitIcon style={{ color: 'white' }} />}
            </IconButton>
          )}

          {onClose ? (
            <IconButton aria-label="close" onClick={onClose}>
              <GridCloseIcon style={{ color: 'white' }} />
            </IconButton>
          ) : null}
        </div>

      </Toolbar>
    </AppBar>
  );
});

export default DialogTitle;